import React from "react";
import "../../assets/styles/loading.scss";
import { Flex } from "antd";

const Loading: React.FC = () => {
  return (
    <Flex>
      <span className="loader" />
    </Flex>
  );
};

export default Loading;
