import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RES_CD } from '../../types/InterfaceTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import fetch_signIn from '../../apis/fetchs/Fetch_signIn';
import fetch_my from '../../apis/fetchs/Fetch_Users';
import fetch_signOut from '../../apis/fetchs/Fetch_SignOut';
import fetch_user from '../../apis/fetchs/Fetch_Users';

export const TOKEN_NAME = 'authToken';

const emptyUserInfo = {
  admin_id: '',
  name: '',
  last_login_dt: '',
};

export type UserToken = {
  token: string;
  issuedAt?: number;
  expirationTime?: number;
  refresh?: string;
};

export type AuthState = {
  jwt: UserToken | undefined;
  mca: UserToken | undefined;
  userInfo: UserInfo;
  status: 'idle' | 'loading' | 'failed';
};

export type UserInfo = {
  admin_id: string | undefined;
  name: string | undefined;
  last_login_dt: string | undefined;
};

const initialState: AuthState = {
  jwt: undefined,
  mca: undefined,
  status: 'idle',
  userInfo: emptyUserInfo,
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveJWT: (state, action: PayloadAction<string>) => {
      state.jwt = { token: action.payload };
      localStorage.setItem(TOKEN_NAME, action.payload);
    },
    removeJWT: (state) => {
      localStorage.removeItem(TOKEN_NAME);
      return initialState;
    },
    saveUserInfo: (state, action: PayloadAction<{ account: string; accountNumberPassword: string }>) => {
      state.userInfo = Object.assign({}, state.userInfo, action.payload);
    },
    logout: (state) => {
      localStorage.removeItem(TOKEN_NAME);
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_signIn.fulfilled, (state, action) => {
      if (action.payload.code === RES_CD.R0000) {
        state.jwt = { token: action.payload.data };

        localStorage.setItem(TOKEN_NAME, action.payload.data?.accessToken);
        localStorage.setItem('refreshToken', action.payload.data?.refreshToken);
      }
    });
    builder.addCase(fetch_signIn.rejected, (state, action) => {});
    builder.addCase(fetch_user.fulfilled, (state, action) => {
      if (action.payload.code === RES_CD.R0000) {
        console.log(action.payload.data);
      }
    });
    builder.addCase(fetch_signOut.fulfilled, (state, action) => {
      localStorage.removeItem(TOKEN_NAME);
      return initialState;
    });
    builder.addCase(fetch_signOut.rejected, (state, action) => {
      localStorage.removeItem(TOKEN_NAME);
      return initialState;
    });
    builder.addCase(fetch_my.rejected, (state, action) => {
      // My 데이터 조회 에러
    });
  },
});

export const useJWT = () => {
  return useSelector((state: RootState) => state.auth.jwt);
};

export const useMCAAuth = () => {
  return useSelector((state: RootState) => state.auth.mca);
};

export const useUserInfo = () => {
  return useSelector((state: RootState) => state.auth.userInfo);
};

export const { saveJWT, removeJWT, saveUserInfo, logout } = authReducer.actions;
export default authReducer.reducer;
