import { ADMIN_API_URL, APIAdminPath, ApiVersion } from './ApiClient';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { logout, saveJWT, TOKEN_NAME } from '../../store/reducers/authReducer';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';
import { notification } from '../../components/common/AntdUtil';

const baseQuery = fetchBaseQuery({
  baseUrl: ADMIN_API_URL + ApiVersion,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  const res = result?.data as APIResponse<any>;
  if (res?.code === RES_CD.R0102) {
    const refreshResult = await baseQuery(
      {
        method: 'POST',
        url: APIAdminPath.RE_SIGN_IN,
      },
      api,
      extraOptions
    );
    const refreshRes = refreshResult.data as APIResponse<any>;
    if (refreshRes?.code === RES_CD.R0000) {
      const { data: newToken, code } = refreshRes;
      api.dispatch(saveJWT(newToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  } else if (res?.code !== RES_CD.R0000) {
    notification.error({
      message: `Error [${api.endpoint}]`,
      description: res?.message ?? '통신 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
      placement: 'bottomRight',
    });
  }
  return result;
};

const adminAPI = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'AdminApis',
  tagTypes: ['health'],
  refetchOnMountOrArgChange: 1,
  endpoints: () => ({}),
});
export default adminAPI;
