import { Outlet } from 'react-router-dom';
import { Layout, Watermark } from 'antd';
import SideMenu from '../menus/SideMenu';
import TopHeader from '../menus/TopHeader';
import { useUserInfo } from '../../store/reducers/authReducer';
import React from 'react';

const ContentLayout: React.FC<{}> = () => {
  const { admin_id } = useUserInfo();
  return (
    // <Watermark
    //   content={admin_id ? `LSCCLUB_${admin_id}` : "LSCLUB"}
    //   gap={[200, 200]}
    //   font={{ color: "rgba(0, 0, 0, 0.04)", fontSize: 26 }}
    //   style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
    // >
    <Layout style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
      <Layout.Header
        style={{
          background: '#120E46',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          position: 'sticky',
          top: 0,
          zIndex: 999,
        }}
      >
        <TopHeader />
      </Layout.Header>
      <Layout
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 50,
          bottom: 0,
        }}
      >
        <SideMenu />
        <Layout style={{ minWidth: '1000px', marginLeft: 270 }}>
          <Layout.Content>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
    // </Watermark>
  );
};
export default ContentLayout;
