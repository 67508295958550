import axios, { AxiosError, AxiosInstance } from 'axios';
import { logout, saveJWT, TOKEN_NAME } from '../../store/reducers/authReducer';

export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_SERVER;
export const ApiVersion = '/v1';

export enum ExchangeAPICommonPath {
  GET_CODE = '/code',
  GET_FAQ = '/faq', // FAQ 조회
  HEALTH_CHECK = '/healthcheck',
}

export enum APIAdminPath {
  SIGN_IN = '/login', // 로그인
  SIGN_OUT = '/logout',
  RE_SIGN_IN = '/login/get_token', //토큰 재발급
  MY = '/my', // 내정보
  USER = '/hitapp/user', // 회원 목록조회
}

export enum APIMCAPath {
  MCA = '/mca',
}

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

const api: AxiosInstance = axios.create({
  baseURL: ADMIN_API_URL + ApiVersion,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (config.headers) {
      config.headers['Authorization'] = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: AxiosError) => {
    const { config, response } = error;
    if (response?.status === 401) {
      const originalRequest = config;
      const { data } = await api.post(APIAdminPath.RE_SIGN_IN);
      const { data: newToken, code } = data;
      if (code === 'R0101') {
        store.dispatch(logout());
      } else {
        store.dispatch(saveJWT(newToken));
        if (originalRequest) {
          originalRequest.headers.authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        }
      }
    }
    return Promise.reject(error);
  }
);
export default api;
