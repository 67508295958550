import React, { useEffect } from 'react';
import { Avatar, Badge, Button, Flex, Tag } from 'antd';
import { RoutePath } from '../routers';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircleTwoTone, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../store/store';
import { useUserInfo } from '../../store/reducers/authReducer';
import { useUserCount } from '../../store/reducers/userReducer';
import fetch_my from '../../apis/fetchs/Fetch_Users';
import fetch_signOut from '../../apis/fetchs/Fetch_SignOut';

const TopHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, last_login_dt } = useUserInfo();

  // useGetUserTotalCountQuery(undefined, {
  //   pollingInterval: 30000,
  // });

  const { normal } = useUserCount();

  useEffect(() => {
    dispatch(fetch_my());
  }, [dispatch]);

  const onLogoutHandler = () => {
    navigate(RoutePath.HOME);
    dispatch(fetch_signOut());
  };

  return (
    <Flex gap="middle" align="center" style={{ width: '100%', minWidth: '1000px', height: '50px' }}>
      <Flex justify={'flex-start'} align="center" gap={10}>
        <Link to={RoutePath.HOME} style={{ display: 'flex' }}>
          <p style={{ color: 'white' }}>LSCLUB</p>
        </Link>
        <Flex>Admin</Flex>
        <Tag color="#108ee9" style={{ padding: '0 5px' }}>
          {process.env.REACT_APP_STAGE === 'PROD' ? '운영계 ' : '개발계'}
        </Tag>
      </Flex>

      <Flex justify="flex-end" align="center" gap={20} style={{ width: '100%' }}>
        <Flex align="center" style={{ paddingRight: '20px', paddingTop: '10px' }}>
          {/*<p>Members</p>*/}
          {/*<Badge count={normal} overflowCount={99999999999}>*/}
          {/*  <Avatar shape="square" icon={<UserOutlined />} />*/}
          {/*</Badge>*/}
        </Flex>
        <Flex gap={5} style={{ paddingRight: '10px' }}>
          {/*<CheckCircleTwoTone twoToneColor="#FF0000FF" />*/}
          {/*<p>MCA</p>*/}
          {/*<CheckCircleTwoTone twoToneColor="#FF0000FF" />*/}
          {/*<p>WEB</p>*/}
          {/*<CheckCircleTwoTone twoToneColor={isAPIError ? '#FF0000FF' : '#52c41a'} />*/}
        </Flex>

        <Flex align="center" gap={10}>
          {/*<p>Last login time : {last_login_dt}</p>*/}
          {/*<Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />*/}
          {/*<p>{name} 님</p>*/}
        </Flex>
        <Button ghost icon={<PoweroffOutlined />} onClick={onLogoutHandler} />
      </Flex>
    </Flex>
  );
};

export default TopHeader;
