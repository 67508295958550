import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Flex, Input, Pagination, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import PageWrapper from '../../components/common/PageWrapper';
import { DataContent, User, UserInput } from '../../types/UserTypes';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';
import api, { APIAdminPath } from '../../apis/clients/ApiClient';

const InfoUser: React.FC = () => {
  const [queryStr, setQueryStr] = useState('');
  const [pagination, setPagination] = useState({ page: 1, size: 20 });
  const [total, setTotal] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [param, setParam] = useState<UserInput>({ page: 1, total: 20, keyword: '' });
  const [dataList, setDataList] = useState<User[]>([]);

  const fetch = useCallback(async () => {
    const response = await api.get<APIResponse<DataContent>>(APIAdminPath.USER, { params: param });
    if (response.data.code === RES_CD.R0000) {
      const mergedDataList = response?.data?.data?.content.map((user) => ({
        ...user.avatar,
        ...user,
      }));
      setTotal(response?.data?.data?.totalElements || 0);
      setNumberOfElements(response?.data?.data?.numberOfElements || 0);
      if (mergedDataList) {
        setDataList(mergedDataList);
      }
    }
  }, [param]);

  const onFetch = useCallback(
    (page: number, size: number) => {
      const p = {
        page: page,
        total: total,
      } as UserInput;

      if (queryStr) {
        p.keyword = queryStr;
      }

      setParam(p);
    },
    [queryStr]
  );

  useEffect(() => {
    fetch();
  }, [param, fetch]);

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      setPagination({ page, size: pageSize });
      onFetch(page, pageSize);
    },
    [onFetch]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '회원번호',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        align: 'right',
      },
      { title: '아이디', dataIndex: 'username', key: 'username' },
      { title: '이메일', dataIndex: 'email', key: 'email' },
      { title: '닉네임', dataIndex: 'nickname', key: 'nickname' },
      { title: '초대코드', dataIndex: 'inviteCode', key: 'inviteCode', width: 120 },
      {
        title: 'LS연동여부',
        dataIndex: 'lsClientNo',
        key: 'lsClientNo',
        render: (value: any) => <input type="checkbox" checked={!!value} readOnly />,
        width: 120,
      },
      {
        title: '마케팅동의여부',
        dataIndex: 'marketing',
        key: 'marketing',
        render: (value: boolean) => <input type="checkbox" checked={value} readOnly />,
        width: 120,
      },
      {
        title: '활성상태',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (value: boolean) => <input type="checkbox" checked={value} readOnly />,
        width: 120,
      },
    ],
    []
  );

  return (
    <PageWrapper>
      <Card>
        <Flex justify="space-between" align="end">
          <Flex gap={30}>
            <Flex vertical gap={10}>
              <span style={{ width: 200 }}>닉네임 검색어</span>
              <Input.Search
                placeholder="검색어 입력"
                value={queryStr}
                allowClear
                onChange={(e) => {
                  setQueryStr(e.target.value);
                }}
                onSearch={() => onFetch(pagination.page, pagination.size)}
              />
            </Flex>
          </Flex>
          <Button icon={<SearchOutlined />} onClick={() => onFetch(pagination.page, pagination.size)}>
            조회
          </Button>
        </Flex>
      </Card>
      <Flex style={{ width: '100%', minHeight: 500 }} vertical justify="center" align={'center'} gap={15}>
        <Table
          style={{ width: '100%', height: '100%' }}
          pagination={{ position: ['none'], pageSize: pagination.size }}
          bordered
          columns={columns}
          dataSource={dataList}
          rowKey={(row) => row.id}
          sticky
          title={() => <Flex> 전체 목록 {numberOfElements}명</Flex>}
          footer={() => (
            <Flex justify="center">
              <Pagination
                className="ant-table-pagination-center"
                total={total}
                showSizeChanger={false}
                showTotal={(total, range) => `총 ${total}개 중 ${range[0]}~${range[1]}`}
                defaultPageSize={pagination.size}
                defaultCurrent={pagination.page}
                pageSize={pagination.size}
                current={pagination.page}
                onChange={handlePageChange}
              />
            </Flex>
          )}
        />
      </Flex>
    </PageWrapper>
  );
};
export default InfoUser;
