import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageProps } from '../../types/Types';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export type UserState = {
  userList: UserList;
  userCount: UserCount;
  pageSizes: LocationPageSize;
};

export type UserList = PageProps & {};
export type UserCount = {
  normal: number;
  withdraw: number;
};

export type LocationPageSize = {
  [key: string]: string; // 인덱스 시그니처
};

const initialState: UserState = {
  userList: { page: 1, size: 10 },
  userCount: { normal: 0, withdraw: 0 },
  pageSizes: {},
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserListPage: (state, action: PayloadAction<{ page: number; size: number }>) => {
      state.userList = { ...action.payload };
    },
    changePageSize: (state, action: PayloadAction<{ location: string; size: number; index?: string }>) => {
      const pay = action.payload;
      state.pageSizes[pay?.index ? `${pay.location}:${pay.index}` : pay.location] = pay.size.toString();
    },
  },
});

export const { changeUserListPage, changePageSize } = userReducer.actions;

export const useUserCount = () => {
  return useSelector((state: RootState) => state.user.userCount);
};
export const useUserList = () => {
  return useSelector((state: RootState) => state.user.userList);
};

export const usePageSize = (index?: string) => {
  const { pathname } = useLocation();
  const pageSizes = useSelector((state: RootState) => state.user.pageSizes[index ? `${pathname}:${index}` : pathname]);
  return useMemo(() => {
    return parseInt(pageSizes) || 20;
  }, [pageSizes]);
};

export default userReducer.reducer;
