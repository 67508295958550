import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import api, { APIAdminPath } from '../clients/ApiClient';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';

/**
 * Admin Controller Fetch : 로그아웃
 */
const fetch_signOut = createAsyncThunk('admin/signout', async (_, thunkAPI) => {
  try {
    const response = await api.post<APIResponse<any>>(APIAdminPath.SIGN_OUT);
    if (response.status !== HttpStatusCode.Ok || response.data.code !== RES_CD.R0000) {
      return thunkAPI.rejectWithValue(response.data);
    }
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export default fetch_signOut;
