import {
  message as antdMessage,
  notification as antdNotification,
  Modal as antdModal,
  App,
} from "antd";

import type { MessageInstance } from "antd/es/message/interface";
import type { NotificationInstance } from "antd/es/notification/interface";
import type { ModalStaticFunctions } from "antd/es/modal/confirm";
antdNotification.config({
  placement: "bottomLeft",
  duration: 5, // 알림이 표시될 기간 (초)
});
let message: MessageInstance = antdMessage;
let notification: NotificationInstance = antdNotification;

// because warn is deprecated, so we need to remove it.
const { warn, ...resetFns } = antdModal;
let modal: Omit<ModalStaticFunctions, "warn"> = resetFns;

/**
 * This component is used to escape the antd's static functions.
 */
function AntdUtil() {
  const staticFunctions = App.useApp();
  message = staticFunctions.message;
  notification = staticFunctions.notification;
  modal = staticFunctions.modal;
  return null;
}

export { message, notification, modal };

export default AntdUtil;
