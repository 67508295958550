import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LockOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  StockOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Flex, Layout, Menu, MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../routers';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  disabled?: boolean
): MenuItem => {
  return {
    key,
    icon,
    children,
    disabled,
    label,
  } as MenuItem;
};

const SideMenu: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openKey, setOpenKey] = useState<string[]>([]);

  useEffect(() => {
    if (pathname || pathname !== '/') {
      setOpenKey([pathname]);
    } else {
      setOpenKey([]);
    }
  }, [pathname]);

  const items: MenuItem[] = useMemo(() => {
    return [
      getItem('회원관리', '1', <UserOutlined />, [getItem('회원정보 조회', RoutePath.INFO_USER)]),
      getItem('ADMIN 관리', '6', <LockOutlined />, []),
    ];
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onMenuClick = useCallback(
    (e: any) => {
      navigate(e.key, { state: { from: 'Menu' } });
      setOpenKey([e.key]);
    },
    [navigate]
  );

  return (
    <Layout.Sider
      width={270}
      style={{
        background: 'white',
        position: 'fixed',
        top: 0,
        bottom: 0,
      }}
    >
      <Flex
        vertical
        style={{
          height: '100%',
          marginTop: 50,
          paddingBottom: 70,
          overflowY: 'auto',
        }}
      >
        <Menu
          style={{ padding: '50 0' }}
          mode={'inline'}
          items={items}
          onClick={onMenuClick}
          defaultOpenKeys={['1', '2', '3', '4']}
          selectedKeys={openKey}
          triggerSubMenuAction={'hover'}
        />
      </Flex>
    </Layout.Sider>
  );
};

export default SideMenu;
