import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { HappyProvider } from '@ant-design/happy-work-theme';
import { BrowserView, MobileView } from 'react-device-detect';

const RootLayout: React.FC<{}> = () => {
  return (
    <Layout id={'app'} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
      <BrowserView>
        <HappyProvider>
          <Outlet />
        </HappyProvider>
      </BrowserView>
      <MobileView>
        <Outlet />
      </MobileView>
    </Layout>
  );
};
export default RootLayout;
