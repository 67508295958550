import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import ContentLayout from '../layouts/ContentLayout';
import { Login } from '../../pages/login';
import { useAuthState } from '../../utils/hooks/useAuth';
import RootLayout from '../layouts/RootLayout';
import React from 'react';
import InfoUser from '../../pages/users';
import Main from '../../pages/main';

export enum RoutePath {
  HOME = '/', //첫화면
  INFO_USER = '/users', //회원 정보 화면
}

const Routers: React.FC = () => {
  const isLogin = useAuthState();
  console.log('isLogin', isLogin);
  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/">
          {!isLogin && <Route index element={<Login />} />}
          <Route path="*" element={<Navigate to={RoutePath.HOME} />} />
        </Route>
        <Route element={<LoginWrapper />}>
          <Route element={<ContentLayout />}>
            <Route index path="/" element={<Main />} />
            {/*회원관리*/}
            <Route path="users">
              <Route index element={<InfoUser />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Routers;
const LoginWrapper = () => {
  const isLogin = useAuthState();
  const { pathname } = useLocation();
  return isLogin ? <Outlet /> : <Navigate to={RoutePath.HOME} state={{ beforePath: pathname }} />;
};
const LogoutWrapper = () => {
  const isLogin = useAuthState();
  const { pathname } = useLocation();
  return isLogin ? <Navigate to={RoutePath.HOME} state={{ beforePath: pathname }} /> : <Outlet />;
};
