import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import api, { APIAdminPath } from '../clients/ApiClient';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';
import { UserInput } from '../../types/UserTypes';

/**
 * USERS Controller Fetch : 사용자 정보
 */
const fetch_user = createAsyncThunk(APIAdminPath.USER, async (_, thunkAPI) => {
  try {
    const response = await api.get<APIResponse<any>>(APIAdminPath.USER);
    if (response.status !== HttpStatusCode.Ok || response.data.code !== RES_CD.R0000) {
      return thunkAPI.rejectWithValue(response.data);
    }
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export default fetch_user;
