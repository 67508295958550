export type APIResponse<T> = {
  code: RES_CD;
  data?: T;
  message?: string;
};

export enum RT_CD_CODE {
  OK = '0',
  SYSTEM_ERROR = '1',
  AP_ERROR = '2',
  TIMEOUT = '3',
}

export enum RES_CD {
  R0000 = 'R0000', // 정상
  R0101 = 'R0101', // 인증에 실패
  R0102 = 'R0102', // 인증이 필요함
  R9999 = 'R9999', // 서버 오류
}
