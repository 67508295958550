import React from "react";
import { Flex } from "antd";

const PageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex gap={10} vertical style={{ margin: 30 }}>
      {children}
    </Flex>
  );
};

export default PageWrapper;
