import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from './components/common/Loading';
import { App as AntApp, ConfigProvider } from 'antd';
import AntdUtil from './components/common/AntdUtil';
import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs';
import Routers from './components/routers';
import { antTheme } from './assets/styles/theme';
import koKR from 'antd/locale/ko_KR';
import { injectStore } from './apis/clients/ApiClient';
import { persistor, store } from './store/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

injectStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConfigProvider locale={koKR} theme={antTheme}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} language="ko">
            <AntApp>
              <AntdUtil />
              <StyleProvider transformers={[legacyLogicalPropertiesTransformer]}>
                <BrowserRouter>
                  <Routers />
                </BrowserRouter>
              </StyleProvider>
            </AntApp>
          </GoogleReCaptchaProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
