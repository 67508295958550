export const antTheme = {
  cssVar: true,
  hashed: false,
  token: { fontFamily: "Noto Sans KR" },
  components: {
    Table: {
      borderColor: "#e5e5e5",
      tableScrollThumbSize: 10,
    },
  },
};
