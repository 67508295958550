import React from 'react';
import { CalendarProps, Card, Flex } from 'antd';
import { Calendar, Tag } from 'antd';
import { Dayjs } from 'dayjs';
import PageWrapper from '../../components/common/PageWrapper';

const Main: React.FC = () => {
  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Tag color={item.type}>{item.content} </Tag>
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  return (
    <PageWrapper>
      <Card>{/*<Calendar cellRender={cellRender} />*/}</Card>
    </PageWrapper>
  );
};
export default Main;
const getListData = (value: Dayjs) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        { type: 'warning', content: 'warning event.' },
        { type: 'success', content: 'usual event.' },
      ];
      break;
    case 10:
      listData = [
        { type: 'warning', content: 'warning event.' },
        { type: 'success', content: 'usual event.' },
        { type: 'error', content: 'error event.' },
      ];
      break;
    case 15:
      listData = [
        { type: 'warning', content: 'warning event' },
        { type: 'success', content: 'very long usual event......' },
        { type: 'error', content: 'error event 1.' },
        { type: 'error', content: 'error event 2.' },
        { type: 'error', content: 'error event 3.' },
        { type: 'error', content: 'error event 4.' },
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value: Dayjs) => {
  if (value.month() === 8) {
    return 1394;
  }
};
